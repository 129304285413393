@font-face {
  font-family: digital-regular;
  src: url(./assets/fonts/digital-regular.ttf);
}

.user-table tr:nth-child(2n){
  background-color: whitesmoke;
}

.user-table span::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
  height: 5px;
}

.form-select:focus{
  box-shadow: none !important;
}