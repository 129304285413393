/* Overlay to cover the entire viewport */
.iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 1000; /* Ensure the overlay is on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container to hold the iframe and close button */
.iframe-container {
  background-color: #fff; /* White background for the iframe container */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Box shadow for a card-like appearance */
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto; /* Enable scrolling if content overflows the container */
  position: relative; /* Relative positioning to place the close button */
  display: contents;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999; /* Ensure the loading screen is on top of other content */
}

/* The iframe that takes up the entire container */
.fullscreen-iframe {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

/* Close button to close the iframe */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff; /* White background for the button */
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Button shadow */
  z-index: 9999;
}

/* Style the close button on hover */
.close-button:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}

.center-spinner {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  position: 'absolute';
  top: 0px;
  left: 0px;
  width: '100%';
  height: '100%';
  z-index: 1;
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
