.tweety-form .formgroup {
    margin-top: 0.5rem !important;
    /* border: solid 1px #ccc; */
    /* background-color: #ffffff; */
    /* border-radius: 5px; */
    border: 0 !important;
    /* box-shadow: 1px 1px 5px rgb(200, 200, 200); */
}
.tweety-form .input-group-bt:after {
    border-bottom: 2px solid #1976d2;
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
}
.input-group-ch:after {
    transform: scaleX(1) !important;
}
.tweety-form-shadow {
    box-shadow: 0px 0px 10px lightgray;
}

.formlabel {
    font-size: 12px;
    padding-left: 2%;
    padding-top: 2%;
}
.form-label-otp {
    font-size: 14px;
}
.form-control-otp {
    font-size: 14px;
    outline: 0;
    background-color: transparent;
    padding-top: 0;
}

.formcontrol {
    outline: 0;
    border-radius: 0 !important;
    border-bottom: 2px solid rgb(196, 194, 194) !important;
    padding-top: 0;
    color: black;
}
.formcontrol::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c2c9c7;
    opacity: 1; /* Firefox */
}

.formcontrol:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c2c9c7;
}

.formcontrol::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #c2c9c7;
}
.text-dark {
    /*float:right;*/
    font-size: 14px;
}
.modal-button {
    font-size: 14px;
}
.formcheck {
    margin-top: 5%;
    font-size: 12px;
}
.form-check-radio {
    font-size: 14px;
    padding-right: 15px;
}
.form-check-select {
    font-size: 14px;
    margin-left: 10px;
    padding-right: 15px;
}

.button {
    padding-left: 5%;
    padding-right: 5%;
    float: right;
    color: black;
    border: none;
}
.formcontrol:focus {
    outline: none;
    background-color: transparent;
}
.button:active,
.button:hover,
.button:focus {
    outline: none;
}
.filegroup {
    margin-top: 5%;
}
#dropmsg {
    background-color: transparent;
}
.form {
    background-color: transparent;
}
#drop {
    border: solid 0px #ccc;
    background-color: transparent;
}
.form {
    margin-left: 5%;
}
.tweety-form .dropzone {
    min-height: 100px;
    box-shadow: 0px 2px 10px lightgray;
    background: transparent;
}

.inputcontrol {
    border: none;
    font-size: 14px;
    outline: 0;
    background-color: #ffffff;
    padding-top: 0;
}
.inputcontrol:focus {
    outline: none;
    background-color: transparent;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #ffffff;
}

.form-control {
    z-index: 0 !important;
}
