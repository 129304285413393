.accordion-item {
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-right: none;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

hr {
  margin-top: 0 1rem !important;
}
